@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");

$red: #e20001;
$black: #191919;

$breakpoint-desktop: 1280px;
$breakpoint-tablet: 960px;
$breakpoint-mobile: 600px;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.container {
  width: 1280px;
  min-width: 1280px;
  height: 80vh;
  margin: 0 auto;
  @media (max-width: $breakpoint-tablet) {
    width: 960px;
    min-width: 960px;
  }
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
  .wrapper {
    padding: 0 48px;
    height: 70%;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 20px;
    }
    .home {
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 80%;
      width: 100%;

      .container {
        .wrapper {
          height: 65%;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: $breakpoint-tablet) {
            height: 65%;
          }
          @media (max-width: $breakpoint-mobile) {
            height: 50%;
          }
        }
      }
      p {
        font-size: 0.9rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      .home-content {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        @media (max-width: $breakpoint-mobile) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        }
        .home-item {
          // width: 15%;
          width: 80%;
          // margin: 5px;
          padding: 10px;
          @media (max-width: $breakpoint-mobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            text-align: center;
          }
          h5 {
            font-size: 24px;
            font-weight: 600;
            @media (max-width: $breakpoint-mobile) {
              margin: 20px 0;
            }
            // @media (max-width: $breakpoint-mobile){
            //   color: $red;
            // }
          }
        }
      }

      .projects {
        .projects-content {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media (max-width: $breakpoint-mobile) {
            margin-top: 20px;
          }
          .contribution-map {
            text-align: center;
            width: 65%;
            @media (max-width: $breakpoint-mobile) {
              width: 90%;
            }
            img {
              width: 100%;
            }
          }
          .projects-actual {
            width: 65%;
            @media (max-width: $breakpoint-mobile) {
              width: 90%;
            }
            li {
              @media (max-width: $breakpoint-mobile) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      .stack {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        @media (max-width: $breakpoint-mobile) {
          margin-top: 20px;
        }
        .stack-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          @media (max-width: $breakpoint-mobile) {
            width: 90%;
          }
        }
      }
      .contact {
        .contact-content {
          .contact-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .contact-form {
              width: 60%;
              @media (max-width: $breakpoint-mobile) {
                width: 90%;
              }
              form {
                .name-input input {
                  width: 60%;
                  @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                  }
                }
                .email-input input {
                  width: 60%;
                  @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                  }
                }
                .message-input textarea {
                  width: 60%;
                  @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                  }
                }
                span {
                  @media (max-width: $breakpoint-mobile) {
                    font-size: 0.5rem;
                    color: $red;
                  }
                }
              }
            }
            .contact-img {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 40%;
                @media (max-width: $breakpoint-mobile) {
                  width: 60%;
                }
              }
            }
          }
          .references {
            .reference-name {
              font-style: italic;
              font-weight: 600;
              @media (max-width: $breakpoint-mobile) {
                font-size: 0.6rem;
              }
            }
            p {
              text-transform: none;
              @media (max-width: $breakpoint-mobile) {
                font-size: 0.6rem;
              }
            }
          }
        }
      }
    }
  }
}

header {
  height: 100px;

  .inner-header {
    position: relative;
    z-index: 10;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-container {
      a {
        text-decoration: none;
      }
      .logo-black {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h5 {
          font-weight: 700;
          font-size: 1rem;
          color: $black;
        }
        .name-black {
          border-bottom: 2px solid $red;
          margin: 0;
          text-decoration: none;
        }
        .title-black {
          margin: 0;
        }
      }

      .logo-red {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h5 {
          font-weight: 700;
          font-size: 1rem;
          color: $red;
        }
        .name-red {
          border-bottom: 2px solid white;
          margin: 0;
          text-decoration: none;
        }
        .title-red {
          margin: 0;
        }
      }
    }

    .git-icon {
      color: $black;
    }

    .menu {
      .button-red {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 1rem;
        mix-blend-mode: difference;
        color: white;
        font-weight: 700;
        &:hover {
          color: $red;
        }
      }
      .button-black {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 1rem;
        mix-blend-mode: difference;
        color: $black;
        font-weight: 700;
        &:hover {
          color: $red;
        }
      }
    }
  }
  .main-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    .menu-secondary-background-color {
      background: $red;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-layer {
      position: relative;
      background: $black;
      height: 100%;
      overflow: hidden;
      .wrapper {
        position: relative;
        .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 200px;
          height: 90%;
          @media (max-width: $breakpoint-tablet) {
            top: 180px;
          }
          @media (max-width: $breakpoint-mobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            top: 100px;
          }
          nav {
            display: block;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                font-size: 5rem;
                font-weight: 700;
                cursor: pointer;
                height: 135px;
                overflow: hidden;
                position: relative;
                width: 700px;
                @media (max-width: $breakpoint-mobile) {
                  width: 400px;
                  display: flex;
                  height: 100px;
                }
                a {
                  @media (max-width: $breakpoint-mobile) {
                    font-size: 3rem;
                  }
                  position: absolute;
                  color: #fff;
                  text-decoration: none;
                  &:hover {
                    color: $red;
                  }
                }
              }
            }
          }
          .info {
            color: #fff;
            width: 300px;
            h3 {
              font-size: 1.2rem;
              margin: 8px auto;
            }
            p {
              margin: 0 auto;
              font-size: 0.8rem;
            }
          }
        }
        .locations {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.8rem;
          font-weight: 600;
          span {
            cursor: pointer;
            &:first-child {
              margin-left: 64px;
            }
            font-weight: 400;
            margin: 0 32px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: #fff;
              background: $black;
              padding: 8px 24px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
